import axios, { AxiosResponse } from 'axios';
import { environment } from '../../../config/env';
import { accessTokenType, saveApplicantType, sumsubSaveResponseType } from './model';
const API_URL = environment.API_URL;
const GET_ACCESS_TOKEN_URL = `${API_URL}/users/sumsub/access-token`;
const SAVE_APPLICANT_ID = `${API_URL}/users/applicant-info`;

export const getApplicantAccessToken = async (token: { token: string }) => {
  return axios.post(`${GET_ACCESS_TOKEN_URL}`, token).then((response: AxiosResponse<accessTokenType>) => response.data);
};
export const saveApplicantID = async (payload: saveApplicantType) => {
  return axios
    .post(`${SAVE_APPLICANT_ID}`, payload)
    .then((response: AxiosResponse<sumsubSaveResponseType>) => response.data);
};

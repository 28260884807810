export enum SumsubReviewAnswerType {
  RED = 'RED',
  GREEN = 'GREEN',
}
export type ISumsubReviewResult = {
  moderationComment: string;
  reviewAnswer: string;
  reviewRejectType: string;
};
export const sumsubReviewResultInitialValue = {
  moderationComment: '',
  reviewAnswer: '',
  reviewRejectType: '',
};
export type accessTokenType = {
  token: string;
  userId: string;
};
export type saveApplicantType = {
  token: string;
  applicantId: string;
};

export type sumsubSaveResponseType = {
  sumsubApplicantId: string;
  sumsubReviewDate: string;
  sumsubReviewAnswer: string;
  sumsubResponse: string;
};

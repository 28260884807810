import { useQuery } from 'react-query';
import { QUERIES } from '../../../config/consts';
import { Spinner } from '../../../../_metronic/helpers/components/Spinner';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  ISumsubReviewResult,
  saveApplicantType,
  SumsubReviewAnswerType,
  sumsubReviewResultInitialValue,
} from '../core/model';
import SumsubWebSdk from '@sumsub/websdk-react';
import { getApplicantAccessToken, saveApplicantID } from '../core/request';

const KycFlow = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';

  const handler = () => Promise.resolve();
  const [sumsubReviewResult, setSumsubReviewResult] = useState<ISumsubReviewResult>(sumsubReviewResultInitialValue);
  const { reviewAnswer, reviewRejectType } = sumsubReviewResult;

  const [applicationStatus, setApplicationStatus] = useState<any>(null);
  const [applicantID, setApplicantID] = useState<any>(null);
  const [showErrorWindow, setShowErrorWindow] = useState(false);
  const config = {
    lang: 'en',
  };
  const options = { addViewportTag: false, adaptIframeHeight: true };
  const { data: applicantToken, isFetching } = useQuery(
    QUERIES.KYC.APPLICANT_ACCESS_TOKEN,
    async () => {
      try {
        return await getApplicantAccessToken({ token });
      } catch (error) {
        setShowErrorWindow(true);
      }
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false, retry: false }
  );

  const messageHandler = (type: any, payload: any) => {
    payload['reviewResult'] && setSumsubReviewResult(payload['reviewResult']);
    payload['reviewStatus'] && setApplicationStatus(payload['reviewStatus']);
    payload['applicantId'] && setApplicantID(payload['applicantId']);
  };

  const errorHandler = (e: any) => {};
  const saveApplicant = async (applicantID: string, token: string) => {
    const payload: saveApplicantType = {
      applicantId: applicantID,
      token,
    };
    try {
      await saveApplicantID(payload);
    } catch (error) {
      //handle error
    }
  };
  useEffect(() => {
    if (applicantID && reviewAnswer && applicantToken?.token && applicationStatus === 'completed') {
      saveApplicant(applicantID, token);
    }
  }, [applicantID, reviewAnswer, applicantToken]);
  return (
    <div className='w-auto h-100'>
      <div className='row m-0 h-100 bg-white w-auto'>
        {reviewAnswer && applicationStatus === 'completed' ? (
          <div className='row justify-content-center w-100 align-items-center'>
            <div className='col-4'></div>
            <div className='col-4 rounded shadow-sm'>
              <div className='text-center mb-1 pt-10'>
                <h2 className='text-dark fw-bolder'>You have completed your KYC verification.</h2>
              </div>
              <div className='mb-10 bg-light-info p-10 m-10 rounded text-center'>
                <div className='text-info'>You can now close this window and continue on Telegram.</div>
              </div>
            </div>
            <div className='col-4'></div>
          </div>
        ) : applicantToken?.token ? (
          <SumsubWebSdk
            accessToken={applicantToken.token}
            expirationHandler={handler}
            config={config}
            options={options}
            onMessage={messageHandler}
            onError={errorHandler}
          />
        ) : (
          showErrorWindow && (
            <div className='row justify-content-center w-100 align-items-center'>
              <div className='col-4'></div>
              <div className='col-4 rounded shadow-sm'>
                <div className='text-center mb-1 pt-10'>
                  <h2 className='text-dark fw-bolder'>Your session has timed out.</h2>
                </div>
                <div className='mb-10 bg-light-info p-10 m-10 rounded text-center'>
                  <div className='text-info'>Please close this window to return to Telegram.</div>
                </div>
              </div>
              <div className='col-4'></div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default KycFlow;
